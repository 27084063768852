import React, { useState } from 'react'
import MainLayout from '../layout/MainLayout'
import PushSVG from '../assets/push.svg'
import { NavLink } from 'react-router-dom'
import { LOCALES } from '../locales/locales'
import { TLanguages } from '../models/ILocales'

interface PushPanels {
  name: string
  link: string
  logo: string
  send?: number
  received?: number
}

const PushMessages = () => {
  const [currentLocale] = useState<TLanguages>(
    localStorage.getItem('currentLocale') as TLanguages
  )
  const [pushPanels] = useState<PushPanels[]>([
    {
      name: LOCALES[currentLocale].TEXT_PUSH,
      link: '/admin/push/text',
      logo: PushSVG
      // send: 50,
      // received: 47
    },
    {
      name: LOCALES[currentLocale].GEO_PUSH,
      link: '/admin/push/geo',
      logo: PushSVG
      // send: 50,
      // received: 47
    },
    {
      name: LOCALES[currentLocale].PUSH_SCHEDULER,
      link: '/admin/push/edit',
      logo: PushSVG
    }
  ])

  return (
    <MainLayout>
      <div className="w-full h-full flex flex-row flex-wrap justify-center gap-3">
        {pushPanels.map(item => (
          <NavLink
            to={item.link}
            className="flex flex-col w-80 h-40 p-5 border-blue-500 border rounded-2xl"
            key={item.name}
          >
            <div className="flex items-start gap-3">
              <img
                src={item.logo}
                alt={item.name}
                className="w-7 h-7 svg-blue"
              />
              <span>{item.name}</span>
            </div>
            {'send' in item && 'received' in item ? (
              <div className="flex justify-between flex-grow items-end">
                <div className="flex flex-col">
                  <span className="text-green-500">
                    {
                      LOCALES[currentLocale]
                        .SENDED_AND_RECEIVED
                    }
                  </span>
                  <span className="font-bold text-green-500">{`${item?.send} / ${item.received}`}</span>
                </div>
              </div>
            ) : null}
          </NavLink>
        ))}
      </div>
    </MainLayout>
  )
}

export default PushMessages
