import { IState } from '../models/IState'
import {
    ACTION_ADD_NOTIFICATION,
    ACTION_CHEK_AUTH,
    ACTION_DELETE_NOTIFICATION,
    ACTION_SET_ADMIN_AUTH,
    ACTION_SET_DOWNLOAD_URLS,
    ACTION_SET_GUEST_AUTH,
    ACTION_SET_LOADING,
    ACTION_SET_ORG,
    ACTION_SET_USER,
    ACTION_SET_GUEST_USER,
    ACTION_SET_USER_REQUEST_LOADING,
    ACTION_SET_SITE_DESIGNS
} from '../utils/reduxConsts'

export const initialState: IState = {
    isAdminAuth: false,
    isGuestAuth: false,
    isLoading: false,
    isUserRequestLoading: false,
    guestUser: {
        id: 0,
        org: 0,
        title: '',
        title_color: '',
        is_show_name: false,
        privacy_policy_link: '',
        use_call_check: false,
        text_shadow: false
    },
    orgData: {
        org: [],
        orgId: 1,
        user: '',
        organization_name: '',
        categories: [],
        utm_sources: [],
        utm_tags:[],
        org_status:''
    },
    notifications: [],
    downloadUrls: {
        google: '',
        apple: '',
        qr_url: ''
    },
    siteDesigns: null
}

export default function reducer(state = initialState, action: any): IState {
    switch (action.type) {
        case ACTION_SET_ADMIN_AUTH:
            return {
                ...state,
                isAdminAuth: action.payload
            }
        case ACTION_SET_GUEST_AUTH:
            return {
                ...state,
                isGuestAuth: action.payload
            }
        case ACTION_SET_LOADING:
            return {
                ...state,
                isLoading: action.payload
            }
        case ACTION_SET_USER_REQUEST_LOADING:
            return {
                ...state,
                isUserRequestLoading: action.payload
            }
        case ACTION_CHEK_AUTH:
            return {
                ...state
            }
        case ACTION_SET_DOWNLOAD_URLS:
            return {
                ...state,
                downloadUrls: action.payload
            }
        case ACTION_SET_ORG:
            return {
                ...state,
                orgData: {
                    ...state.orgData,
                    org: [action.payload.organization_name],
                    orgId: action.payload.id,
                    organization_name: action.payload.organization_name,
                    categories: action.payload.categories,
                    utm_sources:action.payload.utm_sources,
                    utm_tags:action.payload.utm_tags,
                    org_status:action.payload.org_status
                }
            }
        case ACTION_SET_USER:
            return {
                ...state,
                orgData: {
                    ...state.orgData,
                    user: action.payload
                }
            }
        case ACTION_ADD_NOTIFICATION:
            return {
                ...state,
                notifications: [...state.notifications, action.payload]
            }
        case ACTION_DELETE_NOTIFICATION:
            return {
                ...state,
                notifications: [
                    ...state.notifications.filter(item => item.id !== action.id)
                ]
            }
        case ACTION_SET_GUEST_USER:
            return {
                ...state,
                guestUser: action.payload
            }
        case ACTION_SET_SITE_DESIGNS:
            return {
                ...state,
                siteDesigns: action.payload
            }
        default:
            return state
    }
}
