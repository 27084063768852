import React, { useEffect, useState } from 'react'
import MainLayout from '../../layout/MainLayout'
import { ILocation, INotification } from '../../models/IApiResponses'
import {
    delete_locations,
    delete_notifications,
    get_locations,
    get_notifications
} from '../../services/api'
import { useDispatch } from 'react-redux'
import { addNotification } from '../../store/actions'
import { v4 } from 'uuid'
import DeleteSVG from '../../assets/delete.svg'
import EditSVG from '../../assets/edit.svg'
import { Redirect } from 'react-router-dom'
import { LOCALES } from '../../locales/locales'
import { TLanguages } from '../../models/ILocales'

interface IRedirect {
    id: number
    type: 'text' | 'geo'
}

const EditPush = () => {
    const dispatch = useDispatch()
    const [textPush, setTextPush] = useState<INotification[]>([])
    // const [textPush, setTextPush] = useState<any[]>([])
    const [geoPush, setGeoPush] = useState<ILocation[]>([])
    const [redirect, setRedirect] = useState<IRedirect>()
    const [currentLocale] = useState<TLanguages>(localStorage.getItem('currentLocale') as TLanguages)
    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [])

    const getData = () => {
        get_locations()
            .then(res => {
                setGeoPush(res.data)
            })
            .catch(e => {
                dispatch(
                    addNotification({
                        type: 'Error',
                        msg: e.response.data.detail,
                        id: v4()
                    })
                )
            })

        get_notifications()
            .then(res => {
                setTextPush(res.data)
            })
            .catch(e => {
                dispatch(
                    addNotification({
                        type: 'Error',
                        msg: e.response.data.detail,
                        id: v4()
                    })
                )
            })
    }

    const handleDeleteTextPush = (id: number) => {
        delete_notifications(id)
            .then(res => {
                if (res.status === 204) {
                    dispatch(
                        addNotification({
                            id: v4(),
                            type: 'Success',
                            msg: LOCALES[currentLocale].PUSH_DELETING_SUCCESS
                        })
                    )
                }
            })
            .catch(e => {
                dispatch(
                    addNotification({
                        type: 'Error',
                        msg: e.response.data.detail,
                        id: v4()
                    })
                )
            })
            .finally(() => getData())
    }

    const handleDeleteGeoPush = (id: number) => {
        delete_locations(id)
            .then(res => {
                if (res.status === 204) {
                    dispatch(
                        addNotification({
                            id: v4(),
                            type: 'Success',
                            msg: LOCALES[currentLocale].PUSH_DELETING_SUCCESS
                        })
                    )
                }
            })
            .catch(e => {
                dispatch(
                    addNotification({
                        type: 'Error',
                        msg: e,
                        id: v4()
                    })
                )
            })
            .finally(() => getData())
    }

    if (redirect?.id) {
        return (
            <Redirect to={`/admin/push/${redirect.type}?id=${redirect.id}`} />
        )
    }
    return (
        <MainLayout>
            <div className="w-full overflow-hidden overflow-x-auto">
                <div className="w-full text-center text-2xl font-bold">
                    {LOCALES[currentLocale].TEXT_PUSHES}
                </div>
                <table className="table-auto w-full">
                    <thead>
                        <tr className="text-md tracking-wide text-center text-gray-900 border-b border-gray-600">
                            <th className="px-2 py-2">{LOCALES[currentLocale].TEXT_PUSHES_TABLE_COLUMN_TITLE_ID}</th>
                            <th className="px-2 py-2" style={{wordBreak: 'break-all',minWidth: '130px'}}>{LOCALES[currentLocale].TEXT_PUSHES_TABLE_COLUMN_TITLE_NAME}</th>
                            <th className="px-2 py-2" style={{width: '130px'}}>{LOCALES[currentLocale].TEXT_PUSHES_TABLE_COLUMN_TITLE_PERIOD}</th>
                            <th className="px-2 py-2">{LOCALES[currentLocale].TEXT_PUSHES_TABLE_COLUMN_TITLE_TIME}</th>
                            <th className="px-2 py-2">{LOCALES[currentLocale].TEXT_PUSHES_TABLE_COLUMN_TITLE_CATEGORIES}</th>
                            <th className="px-2 py-2">{LOCALES[currentLocale].TEXT_PUSHES_TABLE_COLUMN_TITLE_REPEATABLE}</th>
                            <th className="px-2 py-2">{LOCALES[currentLocale].TEXT_PUSHES_TABLE_COLUMN_TITLE_REPETITION_TYPE}</th>
                            <th className="px-2 py-2">{LOCALES[currentLocale].TEXT_PUSHES_TABLE_COLUMN_TITLE_ACTIONS}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {textPush.map((item, key) => (
                            <tr
                                className="hover:bg-blue-100 transition-all"
                                key={key}
                            >
                                <td className="px-2 py-2 border text-center">
                                    {item.id}
                                </td>
                                <td className="px-2 py-2 border text-center" style={{wordBreak: 'break-all',minWidth: '130px'}}>
                                    {item.title}
                                </td>
                                <td className="px-2 py-2 border text-center">
                                    {item.period}
                                </td>
                                <td className="px-2 py-2 border text-center">
                                    {item.send_time}
                                </td>
                                <td className="px-2 py-2 border text-center">
                                {item.category?.length > 0 ? LOCALES[currentLocale].TEXT_PUSHES_TABLE_YES : LOCALES[currentLocale].TEXT_PUSHES_TABLE_NO}
                                </td>
                                <td className="px-2 py-2 border text-center">
                                    {(item.repeat_no_more || item.repeat_no_more === 0) || 
                                    (item.before_birthday || item.before_birthday === 0) ? 
                                    LOCALES[currentLocale].TEXT_PUSHES_TABLE_YES : LOCALES[currentLocale].TEXT_PUSHES_TABLE_NO}
                                </td>
                                <td className="px-2 py-2 border text-center">
                                    {(item.before_birthday || item.before_birthday === 0) ? 
                                    LOCALES[currentLocale].TEXT_PUSHES_TABLE_BIRTHDAY : ''}
                                    {(item.repeat_no_more || item.repeat_no_more === 0) ?
                                    LOCALES[currentLocale].TEXT_PUSHES_TABLE_PERIODIC : ''}
                                </td>
                                <td className="px-2 py-2 text-center border">
                                    <button
                                        className="w-4 inline mr-4"
                                        onClick={() =>
                                            setRedirect({
                                                id: item.id,
                                                type: 'text'
                                            })
                                        }
                                    >
                                        <img
                                            src={EditSVG}
                                            alt={LOCALES[currentLocale].ALT_TEXT_EDIT}
                                        />
                                    </button>
                                    <button
                                        className="w-4 inline"
                                        onClick={() =>
                                            handleDeleteTextPush(item.id)
                                        }
                                    >
                                        <img src={DeleteSVG} alt={LOCALES[currentLocale].ALT_TEXT_DELETE} />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="w-full text-center text-2xl font-bold mt-5">
                    {LOCALES[currentLocale].GEO_PUSHES}
                </div>
                <table className="table-auto w-full overflow-x-auto">
                    <thead>
                        <tr className="text-md tracking-wide text-center text-gray-900 border-b border-gray-600">
                            <th className="px-2 py-2">{LOCALES[currentLocale].GEO_PUSHES_TABLE_COLUMN_TITLE_POLL_ID}</th>
                            <th className="px-2 py-2">{LOCALES[currentLocale].GEO_PUSHES_TABLE_COLUMN_TITLE_TEXT}</th>
                            <th className="px-2 py-2">{LOCALES[currentLocale].GEO_PUSHES_TABLE_COLUMN_TITLE_THE_ADDRESS}</th>
                            <th className="px-2 py-2">{LOCALES[currentLocale].GEO_PUSHES_TABLE_COLUMN_TITLE_LATITUDE}</th>
                            <th className="px-2 py-2">{LOCALES[currentLocale].GEO_PUSHES_TABLE_COLUMN_TITLE_LONGITUDE}</th>
                            <th className="px-2 py-2">{LOCALES[currentLocale].GEO_PUSHES_TABLE_COLUMN_TITLE_ACTIONS}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {geoPush.map((item, key) => (
                            <tr
                                className="hover:bg-blue-100 transition-all"
                                key={key}
                            >
                                <td className="px-2 py-2 border text-center">
                                    {item.id}
                                </td>
                                <td className="px-2 py-2 border text-center">
                                    {item.text}
                                </td>
                                <td className="px-2 py-2 border text-center">
                                    {item.entity}
                                </td>
                                <td className="px-2 py-2 border text-center">
                                    {item.latitude}
                                </td>
                                <td className="px-2 py-2 border text-center">
                                    {item.longitude}
                                </td>
                                <td className="px-2 py-2 text-center border">
                                    <button
                                        className="w-4 inline mr-4"
                                        onClick={() =>
                                            setRedirect({
                                                id: item.id,
                                                type: 'geo'
                                            })
                                        }
                                    >
                                        <img
                                            src={EditSVG}
                                            alt={LOCALES[currentLocale].ALT_TEXT_EDIT}
                                        />
                                    </button>
                                    <button
                                        className="w-4 inline"
                                        onClick={() =>
                                            handleDeleteGeoPush(item.id)
                                        }
                                    >
                                        <img src={DeleteSVG} alt={LOCALES[currentLocale].ALT_TEXT_DELETE} />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </MainLayout>
    )
}

export default EditPush
