import { AxiosResponse } from 'axios'
import $api from '../http'
import {
    IClients,
    ICreateLocation,
    IFeedback,
    IFeedbackById,
    ILocation,
    ILoginData,
    INotification,
    IOrgData,
    IPayDesign,
    IResponse,
    IWalletDesign,
    ISiteDesign,
    IGuestLoginData,
    ICreateGuest,
    IFeedbackAvailable,
    ICustomerFeedbackData,
    ICustomerFeedbackResponse,
    IGooglePay,
    ICheckCode,
  ILocale,
  IFeedbackAvailableData,
  IStatistics,
  IUTM,
  UTMData,
  ISource,
  ITag,
} from '../models/IApiResponses'

export const login = async (
    username: string,
    password: string
): Promise<IResponse<ILoginData>> => {
    return $api.post('/token/', { username, password })
}

export const guest_login = async (
    phone: string,
    utmSource?: string | null,
    utmTag?: string | null
): Promise<IResponse<IGuestLoginData>> => {
    const queryParams = [];
    if (utmSource) {
        queryParams.push(`utm_source=${utmSource}`);
    }
    if (utmTag) {
        queryParams.push(`utm_tag=${utmTag}`);
    }
    const queryString = queryParams.length > 0 ? `${queryParams.join("&")}` : "";

    return $api.get(`/customers/get_by_phone/?phone=${phone}&spam=false&${queryString}`, {
    });
}

export const get_org = async (): Promise<IResponse<IOrgData[]>> => {
    return $api.get('/organizations/')
}

export const get_clients = async (nextPage?: string): Promise<IResponse<IClients>> => {
    const url = nextPage || '/customers/';
    return $api.get(url);
  }

export const get_feetbacks = async (): Promise<IResponse<IFeedback[]>> => {
    return $api.get('/feedbacks/')
}

export const delete_feetbacks = async (
    name: string
): Promise<IResponse<IFeedbackById[]>> => {
    return $api.delete(`/feedbacks/${name}/`)
}

export const get_feetbacks_by_id = async (
    name: string
): Promise<IResponse<IFeedbackById[]>> => {
    return $api.get(`/feedbacks/${name}/replies`)
}

export const create_feetback = async (
    title: string,
    text: string,
    org: number,
    tg_bot: boolean,
    thank_field: string,
    negative_field: string,
    positive_field: string,
    external_feedback_url: string,
    allow_repeated_feedback:boolean
): Promise<IResponse<IFeedback>> => {
    return $api.post(`/feedbacks/`, {
        org,
        title,
        text,
        tg_bot,
        thank_field,
        negative_field,
        positive_field,
        external_feedback_url,
        allow_repeated_feedback
    })
}

export const get_locations = async (): Promise<IResponse<ILocation[]>> => {
    return $api.get('/locations/')
}

export const get_location_by_id = async (
    id: number
): Promise<IResponse<ILocation>> => {
    return $api.get(`/locations/${id}/`)
}

export const patch_locations = async (
    id: number,
    location: ICreateLocation
): Promise<IResponse<ILocation>> => {
    return $api.patch(`/locations/${id}/`, location)
}

export const create_locations = async (
    location: ICreateLocation
): Promise<IResponse<ILocation>> => {
    return $api.post('/locations/', location)
}

export const delete_locations = async (
    id: number
): Promise<IResponse<ILocation[]>> => {
    return $api.delete(`/locations/${id}/`)
}

export const get_notifications = async (): Promise<
    IResponse<INotification[]>
> => {
    return $api.get('/notifications/')
}

export const get_notification_by_id = async (
    id: number
    // ): Promise<IResponse<INotification>> => {
): Promise<IResponse<any>> => {
    return $api.get(`/notifications/${id}/`)
}

export const patch_notifications = async (
    id: number,
    data: any, // ICreateNotification
    file: any
): Promise<IResponse<INotification>> => {
    if (!data.period) {
        // @ts-ignore
        delete data.period
    }
    // return $api.patch(`/notifications/${id}/`, notification)
    const form = new FormData()
    delete data.picture_telegram
    form.append('body', JSON.stringify(data.body))
    delete data.body
    Object.keys(data).forEach(item => {
        form.append(item, data[item])
    })
    form.append('picture_telegram', file)
    
    return $api.patch(`/notifications/${id}/`, form, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const create_notifications = async (
    data: any, // ICreateNotification
    file: any // ICreateNotification
    // ): Promise<IResponse<INotification>> => {
): Promise<IResponse<any>> => {
    // return $api.post('/notifications/', notification)
    const form = new FormData()
    delete data.picture_telegram
    form.append('body', JSON.stringify(data.body))
    delete data.body
    Object.keys(data).forEach(item => {
        form.append(item, data[item])
    })
    form.append('picture_telegram', file)

    return $api.post('/notifications/', form, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const delete_notifications = async (
    id: number
): Promise<IResponse<INotification[]>> => {
    return $api.delete(`/notifications/${id}/`)
}

export const get_pay_designs = async (): Promise<IResponse<IPayDesign[]>> => {
    return $api.get(`/pay_designs/`)
}

export const patch_pay_designs = async (
    id: number,
    files: any,
    data: any
): Promise<IResponse<IPayDesign[]>> => {
    const form = new FormData()
    delete data.id
    delete data.messages
    delete data.info
    delete data.contacts
    delete data.org
    delete data.logo
    delete data.strip
    delete data.main_image
    Object.keys(data).forEach(item => {
        form.append(item, data[item])
    })
    Object.keys(files).forEach(item => {
        form.append(item, files[item])
    })
    return $api.patch(`/pay_designs/${1}/`, form, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const post_pay_designs = async (
    id: number,
    files: any,
    data: any
): Promise<IResponse<IPayDesign[]>> => {
    const form = new FormData()
    delete data.id
    delete data.messages
    delete data.info
    delete data.contacts
    delete data.logo
    delete data.strip
    delete data.main_image
    Object.keys(data).forEach(item => {
        form.append(item, data[item])
    })
    Object.keys(files).forEach(item => {
        form.append(item, files[item])
    })
    return $api.post(`/pay_designs/`, form, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const get_wallet_designs = async (): Promise<
    IResponse<IWalletDesign[]>
> => {
    return $api.get(`/wallet_designs/`)
}

export const patch_wallet_designs = async (
    id: number,
    files: any,
    data: any
): Promise<IResponse<IWalletDesign[]>> => {
    const form = new FormData()
    delete data.id
    delete data.org
    delete data.logo
    delete data.strip
    delete data.back_fields
    delete data.icon
    Object.keys(data).forEach(item => {
        form.append(item, data[item])
    })
    Object.keys(files).forEach(item => {
        form.append(item, files[item])
    })
    return $api.patch(`/wallet_designs/${1}/`, form, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const post_wallet_designs = async (
    id: number,
    files: any,
    data: any
): Promise<IResponse<IWalletDesign[]>> => {
    const form = new FormData()
    delete data.id
    delete data.logo
    delete data.strip
    delete data.back_fields
    delete data.icon
    Object.keys(data).forEach(item => {
        form.append(item, data[item])
    })
    Object.keys(files).forEach(item => {
        form.append(item, files[item])
    })
    return $api.post(`/wallet_designs/`, form, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const get_locale = async (): Promise<IResponse<ILocale>> => {
  return $api.get(`/locale/`)
  
}

export const get_site_designs = async (): Promise<IResponse<ISiteDesign[]>> => {
    return $api.get(`/site_designs/`)
}

export const patch_site_designs = async (
    id: number,
    files: any,
    data: any
): Promise<IResponse<ISiteDesign[]>> => {
    const form = new FormData()
    delete data.id
    delete data.org
    delete data.background
    delete data.background_mobile
    delete data.main_style_css
    delete data.organization_logo
    Object.keys(data).forEach(item => {
        form.append(item, data[item])
    })
    Object.keys(files).forEach(item => {
        form.append(item, files[item])
    })
    return $api.patch(`/site_designs/${1}/`, form, {
        headers: {
        'Content-Type': 'multipart/form-data',
        }
    })
}

export const post_site_designs = async (
    id: number,
    files: any,
    data: any
): Promise<IResponse<IWalletDesign[]>> => {
    const form = new FormData()
    delete data.id
    delete data.background
    delete data.background_mobile
    delete data.main_style_css
    Object.keys(data).forEach(item => {
        form.append(item, data[item])
    })
    Object.keys(files).forEach(item => {
        form.append(item, files[item])
    })
    return $api.post(`/site_designs/${1}/`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Cache-Control': 'no-store',
        }
    })
}

export const post_create_guest = async (
    guest: ICreateGuest,
    utmSource?: string | null,
    utmTag?: string | null
): Promise<IResponse<IGuestLoginData>> => {
    const queryParams = [];
    if (utmSource !== undefined && utmSource !== null) {
        queryParams.push(`utm_source=${utmSource}`);
    }
    if (utmTag) {
        queryParams.push(`utm_tag=${utmTag}`);
    }
    const queryString = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    return $api.post(`/customers/${queryString}`, guest);
}

export const feedback_available = async (
    customer: number,
    feedback: string
): Promise<IResponse<IFeedbackAvailable>> => {
    return $api.get(
        `/customer-feedbacks/available/?customer=${customer}&feedback=${feedback}`
    )
}

export const get_feedbacks = async (): Promise<IResponse<IFeedbackAvailableData>> => {
    return $api.get('/feedbacks/')
}

export const post_customer_feedbacks = async (
    data: ICustomerFeedbackData
): Promise<IResponse<ICustomerFeedbackResponse>> => {
    return $api.post('/customer-feedbacks/', data)
}

export const get_apple_wallet = async (
    url: string
): Promise<IResponse<any>> => {
    return $api.get(url, {
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'application/vnd.apple.pkpass'
        }
    })
}

export const get_google_pay = async (
    url: string
): Promise<IResponse<IGooglePay>> => {
    return $api.get(url)
}

export const post_call_code = async (
    query: string
): Promise<IResponse<any>> => {
    return $api.post(`/customers/call_code/${query}`, {})
}

export const get_check_code = async (
    query: string
): Promise<IResponse<ICheckCode>> => {
    return $api.get(`/customers/check_code/${query}`)
}
export const get_statistics = async (
    start_date:string,
    end_date:string
): Promise<IResponse<IStatistics>> => {
    return $api.get(`statistics/?start_date=${start_date}&end_date=${end_date}`)
}
//utm
export const get_utm = async (): Promise<IResponse<IUTM[]>> => {
    return $api.get('/utm_mark/list_utm/')
}

export const create_source = async(source:string): Promise<IResponse<ISource>> =>{
    return $api.post("/utm_mark/create_source/", {source})
}
export const create_tag = async(tag:string): Promise<IResponse<ITag>> =>{
    return $api.post("/utm_mark/create_tag/", {tag})
}

export const create_utm = async (utmData: { name: string, utm_source: string, utm_tag?: string | null}): Promise<UTMData> => {
    try {
        const response: AxiosResponse<IResponse<UTMData>> = await $api.post('/utm_mark/create_utm/', utmData);
        return response.data.data;
    } catch (error) {
        throw error;
    }
}

export const list_sources = async (): Promise<IResponse<any>> => {
    return $api.get('/utm_mark/list_sources/')
}
export const list_tag = async (): Promise<IResponse<any>> => {
    return $api.get('/utm_mark/list_tags/')
}
///
