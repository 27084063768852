import React, { useEffect, useState } from 'react'
import { INotification } from '../models/IState'
import { useDispatch } from 'react-redux'
import { deleteNotification } from '../store/actions'
import { LOCALES } from '../locales/locales'
import { TLanguages } from '../models/ILocales'

interface NotificationProps {
  notification: INotification
}

const Notification: React.FC<NotificationProps> = ({ notification }) => {
  const dispatch = useDispatch()
  const [currentLocale] = useState<TLanguages>(
    localStorage.getItem('currentLocale') as TLanguages
  )
  useEffect(() => {
    setTimeout(() => {
      handleRemove()
    }, (notification?.time || 8) * 1000)
    // eslint-disable-next-line
  }, [])

  const handleRemove = () => {
    dispatch(deleteNotification(notification.id))
  }

  const backGround = () => {
    switch (notification.type) {
      case 'Info':
        return ' bg-blue-100 hover:bg-blue-200 border-blue-700'
      case 'Error':
        return ' bg-red-100 hover:bg-red-200  border-red-700'
      case 'Success':
        return ' bg-green-100 hover:bg-green-200  border-l-4 border-green-600'
      default:
        return ' bg-white'
    }
  }

  const notificationStyle: React.CSSProperties = {
    position: 'absolute',
    top: '40px',
    right: '20px'
  }

  return (
    <div
      className={`z-50 w-80 rounded p-3 flex flex-col m-3 border-l-8 transition-all ${backGround()}`}
      style={notificationStyle}
    >
      <button className="self-end" onClick={handleRemove}>
        x
      </button>
      <div>
        {notification?.msg?.length
          ? notification.msg
          : LOCALES[currentLocale].SERVER_ERROR}
      </div>
    </div>
  )
}

export default Notification
